<template>
    <div class="button-editor-container">

        <p style="line-height:1" class="mt-2 mb-1"><strong>Background</strong></p>
        <!-- <a-select v-model="bgType" /> -->
        <p style="line-height:1" class="mt-2 mb-1">Color</p>
        <ColorPicker v-model="form.bg.color" :none="true" :alpha="true" />

        <a-row :gutter="32">
            <a-col :span="12">
                <p style="line-height:1" class="mt-2 mb-1">Image</p>
                <ImageBoxSelector v-model="form.bg.image" :removable="true" />
            </a-col>
            <a-col :span="12">
                <p style="line-height:1" class="mt-2 mb-1">Image Tint</p>
                <ColorPicker v-model="form.bg.tint" :none="true" :alpha="true" />
            </a-col>
           
        </a-row>
        
        <p style="line-height:1" class="mt-2 mb-1">Padding Vertical</p>
        <a-radio-group v-model="form.padding.y" size="small">
            <a-radio-button value="none">
                None
            </a-radio-button>
            <a-radio-button value="xs">
                XS
            </a-radio-button>
            <a-radio-button value="sm">
                SM
            </a-radio-button>
            <a-radio-button value="md">
                MD
            </a-radio-button>
            <a-radio-button value="lg">
                LG
            </a-radio-button>
            <a-radio-button value="xl">
                XL
            </a-radio-button>
        </a-radio-group>

        <p style="line-height:1" class="mt-2 mb-1">Padding Horizontal</p>
        <a-radio-group v-model="form.padding.x" size="small">
            <a-radio-button value="none">
                None
            </a-radio-button>
            <a-radio-button value="xs">
                XS
            </a-radio-button>
            <a-radio-button value="sm">
                SM
            </a-radio-button>
            <a-radio-button value="md">
                MD
            </a-radio-button>
            <a-radio-button value="lg">
                LG
            </a-radio-button>
            <a-radio-button value="xl">
                XL
            </a-radio-button>
        </a-radio-group>

        <p style="line-height:1" class="mt-2 mb-1">Margin Vertical</p>
        <a-radio-group v-model="form.margin.y" size="small">
            <a-radio-button value="none">
                None
            </a-radio-button>
            <a-radio-button value="xs">
                XS
            </a-radio-button>
            <a-radio-button value="sm">
                SM
            </a-radio-button>
            <a-radio-button value="md">
                MD
            </a-radio-button>
            <a-radio-button value="lg">
                LG
            </a-radio-button>
            <a-radio-button value="xl">
                XL
            </a-radio-button>
        </a-radio-group>
    </div>

</template>

<script>
export default {
    components:{
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
        ColorPicker: () => import('@/components/site/ColorPicker'),
    },
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    computed:{
        linkType(){
            let types =  [{label:'External Link',value:'link'},{label:'Goto Section',value:'section'}]
            if (!this.$store.getters.isLanding) types.push({label:'Goto Page',value:'page'})
            return types
        },
        pageSectionsOptions(){
            return this.$store.getters.pageSectionsOptions
        },
        pagesOptions(){
            return this.$store.getters.pagesOptions
        },
    },
    data(){
        return {
            watch:false,
            form:{
                bg:{
                    color:'#FFFFFF',
                    tint:'rgba(100,100,100,0.0)',
                    image:''
                },
                padding:{
                    x:'none',
                    y:'none',
                },
                margin:{
                    y:'none'
                }
            },
        }
    },
    watch:{
        form:{
            deep:true,
            handler(val,oldVal){
                if (!this.watch) return this.watch = true;
                return this.$emit('input', val)
            }
        }
    },
    created(){
        let value = this.value || {
            bg:{
                color:'#FFFFFF',
                tint:'rgba(100,100,100,0.0)',
                image:''
            },
            padding:{
                x:'none',
                y:'none',
            },
            margin:{
                y:'none'
            }
        }

        this.form = {
            ...this.form,
            ...value
        }
    },
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>